import React, { useState, useRef, useEffect } from "react";

const SpinWheel = () => {
  const canvasRef = useRef(null);
  const [isSpinning, setIsSpinning] = useState(false);
  const [totalPoints, setTotalPoints] = useState(
    parseInt(localStorage.getItem("userPoints")) || 0
  );
  const [rotation, setRotation] = useState(0);
  const [currentSpeed, setCurrentSpeed] = useState(0);
  const segments = ["Reward 1", "Reward 2", "Reward 3", "Reward 4", "Reward 5"];
  const segColors = ["#EE4040", "#F0CF50", "#815CD1", "#3DA5E0", "#34A24F"];
  const rewards = {
    "Reward 1": 10,
    "Reward 2": 20,
    "Reward 3": 30,
    "Reward 4": 40,
    "Reward 5": 50,
  };

  const drawWheel = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;
    const arcSize = (2 * Math.PI) / segments.length;

    ctx.clearRect(0, 0, width, height);
    ctx.strokeStyle = "black";
    ctx.lineWidth = 2;

    for (let i = 0; i < segments.length; i++) {
      const angle = i * arcSize;
      ctx.beginPath();
      ctx.fillStyle = segColors[i];
      ctx.moveTo(width / 2, height / 2);
      ctx.arc(width / 2, height / 2, width / 2, angle, angle + arcSize, false);
      ctx.lineTo(width / 2, height / 2);
      ctx.fill();
      ctx.stroke();

      // Draw text
      ctx.save();
      ctx.translate(
        width / 2 + Math.cos(angle + arcSize / 2) * (width / 3),
        height / 2 + Math.sin(angle + arcSize / 2) * (height / 3)
      );
      ctx.rotate(angle + arcSize / 2);
      ctx.fillStyle = "white";
      ctx.textAlign = "center";
      ctx.font = "bold 16px Arial";
      ctx.fillText(segments[i], 0, 0);
      ctx.restore();
    }
  };

  const spinWheel = () => {
    if (isSpinning) return;
    setIsSpinning(true);

    const randomSegment = Math.floor(Math.random() * segments.length);
    const spins = Math.floor(Math.random() * 5) + 5; // Minimum 5 spins
    const finalAngle =
      spins * 360 + randomSegment * (360 / segments.length) + 360 / segments.length / 2;

    let currentSpeed = 20; // Initial speed
    const deceleration = 0.2; // Gradual deceleration

    const spinInterval = setInterval(() => {
      setRotation((prev) => (prev + currentSpeed) % 360);
      currentSpeed -= deceleration;

      if (currentSpeed <= 0) {
        clearInterval(spinInterval);
        setIsSpinning(false);

        const winningIndex = Math.floor(((finalAngle % 360) + 360) % 360 / (360 / segments.length));
        const winner = segments[winningIndex];
        const points = rewards[winner];

        setTotalPoints((prevPoints) => {
          const newTotal = prevPoints + points;
          localStorage.setItem("userPoints", newTotal);
          return newTotal;
        });

        setTimeout(() => {
          alert(`Congratulations! You won ${winner} and earned ${points} points.`);
        }, 500);
      }
    }, 30);
  };

  useEffect(() => {
    drawWheel();
  }, [rotation]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <h1>Spin the Wheel Game</h1>
      <p>Total Points: {totalPoints}</p>
      <div style={{ position: "relative", width: "400px", height: "400px" }}>
        <canvas
          ref={canvasRef}
          width={400}
          height={400}
          style={{
            transform: `rotate(${rotation}deg)`,
            borderRadius: "50%",
          }}
        ></canvas>
        <button
          onClick={spinWheel}
          disabled={isSpinning}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "15px 30px",
            fontSize: "16px",
            borderRadius: "50%",
            border: "2px solid black",
            backgroundColor: "white",
            cursor: isSpinning ? "not-allowed" : "pointer",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          {isSpinning ? "Spinning..." : "Spin"}
        </button>
      </div>
    </div>
  );
};

export default SpinWheel;
