import { Card, CardActionArea, CardContent, CardMedia, CircularProgress } from '@mui/material';
import { Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { PngIcons } from '../../../icons';
import { FlowService, PassService } from '../../../services';
import localforage from 'localforage';
import { BrandContext } from '../../../context/Brand.context';
import CustomButton from '../../../components/CustomButton';
import CustomsModal from '../../../components/CustomModal';

function Social() {
    const [filteredData, setFilteredData] = useState();
    const [loader, setLoader] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selected, setSelected] = useState('');
    const brand = useContext(BrandContext)
    const [state, setState] = useState({
        loader: true,
        generalLoader: false,
        apiError: '',
        brandingLoader: false,
        termConditions :false,
        promoData: {}
    })

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    function getDayName(number) {
        if (number >= 1 && number <= 7) {
            return dayNames[number % 7]; // Using modulo operator to handle numbers greater than 7
        } else {
            return "Invalid day number"; // Handling invalid numbers
        }
    }

    const onLoad = async () => {
        let flow = await localforage.getItem('flow');
        console.log('mskingcommunivstion', flow);
    
        if ((!flow) || flow.length === 0) {
          const { response, error } = await FlowService.Get({ query: { brandId: brand._id, delete: false, flowType: 'new' } })
          console.log('loyalty Xoop Jeep', response, error);
    
          if (response) {
            localforage.setItem('flow', response.data);
            flow = response.data
          }
        }
    
        let tempFlow = []
    
        console.log('flow',flow);
        
    
        if(flow[0].flow.length>0){
          for (const value of flow[0].flow) {
            if(value.active!==true&&value.active!==false) {
              // value.active===true
              tempFlow.push({...value, active: true})
            }
            else{
              tempFlow.push(value)
            }
          }
        }
    
    console.log('tempFlow',tempFlow);
    
    flow[0].flow=tempFlow
    
    console.log('floww',flow);
    
        setFilteredData(flow)
        setLoader(false)
      }
    
      useEffect(onLoad, [])

      const [htmlCode, setHtmlCode] = useState("<div>Your HTML code here...</div>");

    const handleChange = (e) => {
        setHtmlCode(e.target.value);
    };

      const [subject, setSubject] = useState("Subject");

    const handleChangeSubject = (e) => {
        setSubject(e.target.value);
    };

    const [model, setModel] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);

    const [ca, setca] = useState()
    useEffect(async() => {
        const customers = await localforage.getItem("customersAudience");
        setca(customers)
    }, [])
    

  return (
    <div>
        <div className='d-flex align-items-center space-between mb_8'>
            <div className='B16R color-neutral100'>Drive Engagement with Social Reward Emails</div>
            <CustomButton disabled={selectedIndex===0&&selected===''} btntext={"Send"} icon={state.generalLoader && <CircularProgress color='inherit' size={14} />} onClick={async()=>{
                // setState({ generalLoader: true, apiError: '' })
                setModel(true)
                // setState({ generalLoader: false, apiError: '' })
            }} />
        </div>
        <div className='bar mt_10 mb_20'></div>
        <div style={{ 
                                display: 'grid', 
                                // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', 
                                gridTemplateColumns: 'repeat(2, 1fr)', 
                                gap: '16px' 
                                }}>
                {filteredData && filteredData.length > 0 && 
                filteredData.map((element, index) => (
                  element?.flow?.length > 0 && element.flow.map((ele, ind) => (

                    
                    
                    
                    (ele.state[0].key==='social') &&
                    // (ele.state[0].platform==='insta') &&
                    
                    <Card 
                    style={{
                        backgroundColor: ind === selectedIndex && '#F2F6FF',
                        border: ind === selectedIndex && '2px solid #2960EC'
                    }}
                    onClick={()=>{
                        setSelectedIndex(ind)
                        setSelected(ele.state[0])
                    }}
                    key={`${index}-${ind}`} sx={{ flex: '1 1 300px', height: 'fit-content' }} >
                    {console.log('1',index)}
                    {console.log('2',ind)}
                    {console.log('aleeeza',ele.state)}
                    {console.log('aleeeza',ele.state1)}
                    <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                      <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <div className="d-flex gap-6">
                          <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                          {ele.state[0].platform === "insta" ?'Instagram Hashtag':"Google Reviews"}
                            
                        </Typography>
                          {/* {ele?.active!==false&&<img src={PngIcons.tick} width={16} height={16}/>} */}
                          {<img src={ele.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}
                        </div>
                        <Typography style={{fontSize: '13px', color: '#2E3039', fontWeight: 400, fontFamily: 'Inter'}} color="text.secondary" sx={{
                          overflow: 'hidden',
                          display: '-webkit-box', fontFamily: 'Inter',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          textOverflow: 'ellipsis',
                        }}>
                          {ele.state[0].platform === "insta" ?"Earn "+(ele.state1[0]?.givePoints ? ele.state1[0]?.givePoints : ele.state1[1]?.givePoints)+" points for sharing hashtag #"+(ele.state1[0]?.addTag ? ele.state1[0]?.addTag : ele.state1[1]?.addTag)+" on Instagram. Share & get rewarded!":"Earn bonus points for sharing on Google reviews. Share & get rewarded!"}
                        </Typography>
                      </CardContent>
                      <CardMedia
                        component="img"
                        height="140"
                        style={{ padding: 12, width: '140px' }}
                        image={
                          ele.state[0].key === "referrallogin"? //receiver
                            PngIcons.NewRBlock:
                            ele.state[0].key === "referral"? //sender
                            PngIcons.NewRBlock:
                            ele.state[0].key === "customerInactivity"? 
                            PngIcons.NewIABlock:
                            (ele.state[0].key === "event" && ele.state[0].festival === "Birth Day" ) ? 
                            PngIcons.newBD:
                            (ele.state[0].key === "visit"||(ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week"))) ? 
                            PngIcons.newCouponBlock:
                            ele.state[0].key === "point"? 
                            PngIcons.newRewardBlock:
                            ele.state[0].key === "social"? 
                            ele.state[0].platform === "insta" ? 'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/d720cafe-acd4-45f8-8e6f-b407b6f51d00/walletly':
                            ele.state[0].platform === "google" ? 'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/a105d275-2a4f-4d61-0f17-29179feb3900/walletly':
                            PngIcons.socialBanner:
                          'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg'}
                        alt={ele.block || element.name || element.key}
                      />
                    </CardActionArea>
                  </Card>
                  
                ))))
              }
            </div>
            {/* <div className='bar mt_10 mb_20'></div> */}
            {console.log('aleezaselected',selected)}
            
            {selected.platform==='insta'&&<div className='middle pt_28 mt_42' style={{background: '#F5F7FC'}}><img src={'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/2448033d-12fd-4f55-cfcc-120ca58cfe00/walletly'} /></div>}
            {selected.platform==='google'&&<div className='middle pt_28 mt_42' style={{background: '#F5F7FC'}}><img src={'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/54587544-2a1f-48cf-8de6-804aad5a9800/walletly'} /></div>}


            {/*  */}
            <CustomsModal
                open={model}
                minWidth={"600px"}
                component={<>
                <div className="H22M">Confirm</div>
                <div className="B14R mt_10 mb_20">{'Are you sure you want to send bulk email to '+ca?.length+' audience?'}</div>
                <div className="d-flex justify-content-end gap-10">
                    <CustomButton
                    varient="primary"
                    btntext={"YES"}
                    icon={deleteLoader &&
                        <CircularProgress
                            size={20}
                            color={'inherit'} />}
                    onClick={async() => {
                        setDeleteLoader(true)
                        // 
                        const api = await PassService.sendBulkSocialInPerson({toaster: true,payload: {
                            brandId: brand._id,
                            index: selectedIndex
        
                        }})
                        console.log('api',api)
                        // 
                    setDeleteLoader(false)
                    setModel(false)
                    }}
                    />
                    <CustomButton
                    varient="secondary"
                    btntext={"NO"}
                    onClick={async() => {
                        setModel(false)
                    }}
                    />
                    </div>
                    </>
                    }
                    // onClose={() => setrefmodel(false)}
                    />
            {/*  */}
    </div>
  )
}

export default Social