// CSS (do not change the orders)
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/global.scss";

import React, { useContext, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import {
  AuthGuard,
  CentralGuard,
  UserOnBoardGuard,
  VerifyGuard,
  CreateBrandGuard,
} from "./utils/RouteGuards";

// Pages
import Main from "./pages/Main";
import Auth from "./pages/auth/Auth";
import EmailVerify from "./pages/auth/EmailVerify";

//temp for checking
import UserOnboarding from "./pages/auth/UserOnboarding";
import { withToaster } from "./context/Toaster.context";
import { withLayout } from "./context/Layout.context";
import { withAgency, AgencyContext } from "./context/Agency.context";

import Pass from "./pages/public/Pass";
import LandingPage from "./pages/public/LandingPage";
import { UserContextComponent } from "./context/User.context";
import { User } from "./enums";
import AgencyInvite from "./pages/public/AgencyInvite";
import LandingPageDefault from "./pages/public/LandingPageDefault";

import LandingPagePoints from "./pages/public/LandingPagePoints";
import LandingPageValidation from "./pages/public/LandingPageValidation";
import LandingPageCongrats from "./pages/public/LandingPageCongrats";
import LandingPageDashboard from "./pages/public/LandingPageDashboard";
import LandingPageDeal from "./pages/public/LandingPageDeal";
import LandingPageDealNew from "./pages/public/LandingPageDealNew";
import LandingPageRedeem from "./pages/public/LandingPageRedeem";
import ManychatDealPage from "./pages/public/ManychatDealPage";
import CurrentPoint from "./pages/public/CurrentPoint";
import LandingPageQrCode from "./pages/public/LandingPageQrCode";
import StripeCoupon from "./pages/public/StripeCoupon";
import BusinessCheckout from "./pages/public/BusinessCheckout";
import LandingPageContact from "./pages/public/LandingPageContact";
import BusinessCheckoutRedeemGift from "./pages/public/BusinessCheckoutRedeemGift";
import ShortUrl from "./pages/public/ShortUrl";
import Terms from "./pages/public/Terms";
import Privacy from "./pages/public/Privacy";

import { Helmet } from "react-helmet";
import { PngIcons } from "./icons";
import LightspeedRedirect from "./pages/public/LightspeedRedirect";
import CarouselTest from "./pages/public/CarouselTest";
import VipClub from "./pages/public/VIPClub";
import MainOnboard from "./pages/onboard/mainOnboard";
import SetupCompleted from  './pages/onboard/SetupWalletly/RewardsRedeem/SetupCompleted'
import MainSetup from "./pages/onboard/SetupWalletly/CloudWaitress/MainSetup";
import MainRewardsRedeems from "./pages/onboard/SetupWalletly/RewardsRedeem/MainRewardsRedeems";
import MainSetupAdvanced from "./pages/onboard/SetupWalletly/rule/MainSetupAdvanced";
import BusinessPage from "./pages/public/BusinessPage";
import MainSetupAdvanced2 from "./pages/onboard/SetupWalletly/rule/MainSetupAdvanced2";
import InfoPage from './pages/onboard/InfoPage'
import RefferalPage from "./pages/dashboard/subpages/RefferalPage";
import Tier from "./pages/public/Tier";
import Null from "./Null";
import { MainOnboard2 } from "./pages/onboard/mainOnboard2";
import UnsubscribeButton from "./Unsubscribe";
import SpinWheel from "./SpinWheel";
import DeletePass from "./DeletePass";

function App(props) {
  const agency = useContext(AgencyContext);

  return (
    <UserContextComponent {...props}>
      <div className="App">
        {
        agency && agency.whiteLabel ? (
          <Helmet key={agency?.favicon}>
            <title>{agency.agencyName}</title>
            <link rel="icon" type="image/png" href={agency?.favicon} sizes="32x32" />
            <link rel="apple-touch-icon" sizes="57x57"  href={agency?.favicon} />
            <link rel="apple-touch-icon" sizes="114x114"  href={agency?.favicon} />
            <link rel="apple-touch-icon" sizes="72x72" href={agency?.favicon} />    
            <link rel="apple-touch-icon" sizes="144x144" href={agency?.favicon} />
            <link rel="apple-touch-icon" sizes="60x60"  href={agency?.favicon} />
            <link rel="apple-touch-icon" sizes="120x120" href={agency?.favicon} />
            <link rel="apple-touch-icon" sizes="76x76"  href={agency?.favicon} />
            <link rel="apple-touch-icon" sizes="152x152"  href={agency?.favicon} />
            <link rel="apple-touch-icon" sizes="180x180"  href={agency?.favicon} />
          </Helmet>
        ) : (
          <Helmet>
            <title>{agency.agencyName}</title>
            <link rel="icon" type="image/png" href={PngIcons.walletlyLogoFull} sizes="32x32" />
            <link rel="apple-touch-icon" sizes="57x57"  href={PngIcons.walletlyLogoFull} />
            <link rel="apple-touch-icon" sizes="114x114"  href={PngIcons.walletlyLogoFull} />
            <link rel="apple-touch-icon" sizes="72x72" href={PngIcons.walletlyLogoFull} />    
            <link rel="apple-touch-icon" sizes="144x144" href={PngIcons.walletlyLogoFull} />
            <link rel="apple-touch-icon" sizes="60x60"  href={PngIcons.walletlyLogoFull} />
            <link rel="apple-touch-icon" sizes="120x120" href={PngIcons.walletlyLogoFull} />
            <link rel="apple-touch-icon" sizes="76x76"  href={PngIcons.walletlyLogoFull} />
            <link rel="apple-touch-icon" sizes="152x152"  href={PngIcons.walletlyLogoFull} />
            <link rel="apple-touch-icon" sizes="180x180"  href={PngIcons.walletlyLogoFull} />
          </Helmet>
        )
        }
        <BrowserRouter>
          <Switch>
            <AuthGuard exact path="/" component={Auth} />

            <AuthGuard path="/forget" component={Auth} />
            <AuthGuard path="/reset" component={Auth} />
            <AuthGuard path="/signup" component={Auth} />

            <VerifyGuard path="/verify" component={EmailVerify} />
            <UserOnBoardGuard path="/onboard" component={UserOnboarding} />
            <CreateBrandGuard
              role={[User.Roles.ADMIN]}
              path="/brandType"
              component={MainOnboard2}
            />
            <CreateBrandGuard
              role={[User.Roles.ADMIN]}
              path="/brand"
              component={MainOnboard}
            />

            {/* Public pages */}
            <Route path="/crousel" component={CarouselTest} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />
            <Route path="/pass/:passCode" component={Pass} />
            <Route path="/tier" component={Tier} />
            <Route
              exact
              path="/page/default/:campaignId/:brandId"
              component={LandingPageDefault}
            />
            {/* <Route exact path="/page/default/:campaignId/:brandId/:dealId/:referalCode" component={LandingPageDefaultReferal} /> */}

            <Route
              exact
              path="/page/businesscheckout"
              render={() => <BusinessCheckout />}
            />
            <Route
              exact
              path="/spin"
              render={() => <SpinWheel />}
            />
            <Route
              exact
              path="/page/businesscheckout/giftcard"
              render={() => <BusinessCheckoutRedeemGift />}
            />
            <Route
              exact
              path="/page/lightspeed"
              render={() => <LightspeedRedirect />}
            />
            <Route
              exact
              path="/page/null"
              render={() => <Null />}
            />
            <Route
              exact
              path="/page/unsubscribe"
              render={() => <UnsubscribeButton />}
            />
            <Route
              exact
              path="/page/deletepass"
              render={() => <DeletePass />}
            />
            <Route
              exact
              path="/page/:growthtoolId"
              render={() => <LandingPage />}
            />

            {/* <Route exact path = "/page/businesscheckout/:brandId/:passcode/:flowId" component = {BusinessCheckout} />
            
            <Route exact path = "/page/businesscheckout/:brandId/:passcode/:dealid/:subtract/:condition/:conditionValue/:flowId" component = {BusinessCheckout} />
            
            <Route exact path = "/page/businesscheckout/:brandId/:passcode/:dealid/:subtract/:condition/:conditionValue" component = {BusinessCheckout} /> */}

            <Route
              path="/page/:growthtoolId/points"
              component={LandingPagePoints}
            />
            <Route
              path="/page/:growthtoolId/validation"
              component={LandingPageValidation}
            />
            <Route
              path="/page/:growthtoolId/congrats"
              component={LandingPageCongrats}
            />
            <Route
              path="/page/:growthtoolId/dashboard"
              component={LandingPageDashboard}
            />
            <Route
              path="/page/:growthtoolId/deal"
              component={LandingPageDeal}
            />
            <Route
              path="/page/:growthtoolId/contact"
              component={LandingPageContact}
            />
            <Route
              path="/page/:growthtoolId/listing"
              render={() => <LandingPageDealNew />}
            />
            <Route
              path="/page/:growthtoolId/redeem"
              component={LandingPageRedeem}
            />
            <Route
              path="/page/:growthtoolId/qrcode"
              component={LandingPageQrCode}
            />
            <Route
              path="/page/:brandId/:passId/:flowId"
              component={ManychatDealPage}
            />
            <Route path="/page/:brandId/vipClub" component={VipClub} />

            <Route path="/page/:brandId/businesspage" component={BusinessPage} />

            <Route path = "/points" component               = {CurrentPoint} />
            <Route path = "/coupon" component               = {StripeCoupon} />
            <Route path = "/:id/accountsetup/advance" component = {MainSetupAdvanced} />
            <Route path = "/:id/accountsetup/final" component   = {MainSetupAdvanced2} />
            
            <Route path = "/:id/rewards" component          = {MainRewardsRedeems} />

            <Route path="/:id/onBoards" component={MainOnboard} />
            <Route path="/info" component={InfoPage} />

            <Route path="/:id/rewardSetupCompleted" component={SetupCompleted} />
            <Route path="/:id/rewards" component={MainRewardsRedeems} />

            <Route path="/:id/RefferalPage" component={RefferalPage} />
            {/* <Route path="/:id/:refid" component={RefferalPage} /> */}

            <Route path="/:id/accountsetup" component={MainSetup} />

            <Route exact path="/url/:passCode" component={ShortUrl} />
           
            <CentralGuard path="/invite" component={AgencyInvite} />


            <CentralGuard path="/:_id" component={withLayout(Main)} />
          </Switch>
        </BrowserRouter>
      </div>
    </UserContextComponent>
  );
}

export default withToaster(withAgency(App));
