import { Card, CardActionArea, CardContent, CardMedia, CircularProgress } from '@mui/material';
import { Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { PngIcons } from '../../../icons';
import { FlowService, PassService, RedeemingWayService } from '../../../services';
// import localforage from 'localforage';
import { BrandContext } from '../../../context/Brand.context';
import CustomButton from '../../../components/CustomButton';
import localforage, * as localForage from "localforage";
import { enums } from '../../../enums';
import axios from 'axios';
import CustomsModal from '../../../components/CustomModal';

function SocialCW() {
    const [filteredData, setFilteredData] = useState();
    const [loader, setLoader] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selected, setSelected] = useState();
    const brand = useContext(BrandContext)
    
    const [state, setState] = useState({
      apiError: "",
      generalLoader: false,
      apiError: '',
      brandingLoader: false,
      termConditions :false,
      promoData: {},
      loader: false,
      deleteLoader: false,
      deleteModal: false,
      mainLoader: true,
      deleteItem: "",
      deleteIndex: "",
      result: [],
    });
  

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    function getDayName(number) {
        if (number >= 1 && number <= 7) {
            return dayNames[number % 7]; // Using modulo operator to handle numbers greater than 7
        } else {
            return "Invalid day number"; // Handling invalid numbers
        }
    }

    const onLoad = async () => {

      const query = { brandId: brand._id, delete: false}
      let redeemingWays = await localForage.getItem("redeemingWays");
      console.log('redeemingWays',redeemingWays);
      if(!redeemingWays){
        console.log('redeemingWays in',redeemingWays);
        redeemingWays = await RedeemingWayService.GetQueriedEarningWays({ query })
        console.log('redeemingWays res',redeemingWays);
        if (redeemingWays?.response){
          localForage.setItem("redeemingWays", redeemingWays.response.data);
        }
      }
      
      if (redeemingWays?.response?.data) {
        redeemingWays.response.data.sort((a, b) => {
          if (a.created > b.created) return -1;
          else return 1;
        })
        setState({ ...state, mainLoader: false, redeemingWays: redeemingWays?.response?.data });
      }
      else if (redeemingWays) {console.log('redeemingWays',redeemingWays)
        redeemingWays?.sort((a, b) => {
          if (a?.created > b?.created) return -1;
          else return 1;
        })
        setState({ ...state, mainLoader: false, redeemingWays: redeemingWays });
      }
    };
    
      useEffect(onLoad, [])

      const [htmlCode, setHtmlCode] = useState("<div>Your HTML code here...</div>");

    const handleChange = (e) => {
        setHtmlCode(e.target.value);
    };

      const [subject, setSubject] = useState("Subject");

    const handleChangeSubject = (e) => {
        setSubject(e.target.value);
    };

    const [model, setModel] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);

    const [ca, setca] = useState()
    useEffect(async() => {
        const customers = await localforage.getItem("customersAudience");
        setca(customers)
    }, [])
    

  return (
    <div>
      {console.log('state123',state)
      }
        <div className='d-flex align-items-center space-between mb_8'>
        <div className='B16R color-neutral100'>Drive Engagement with Social Reward Emails</div>
            <CustomButton btntext={"Send"} icon={state.generalLoader && <CircularProgress color='inherit' size={14} />} onClick={async()=>{
                // setState({ generalLoader: true, apiError: '' })
                setModel(true)
                // setState({ generalLoader: false, apiError: '' })
            }} />
        </div>
        <div className='bar mt_10 mb_20'></div>
        <div style={{ 
                                display: 'grid', 
                                // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', 
                                gridTemplateColumns: 'repeat(2, 1fr)', 
                                gap: '16px' 
                                }}>
              {state?.redeemingWays && state?.redeemingWays.length > 0 && 
                state?.redeemingWays.map((element, ind) => (
                  element.redeemingWayType === 'social'&&
                  // element.platform === 'insta'&&
                  
                  <Card
                  style={{
                    backgroundColor: ind === selectedIndex && '#F2F6FF',
                    border: ind === selectedIndex && '2px solid #2960EC'
                }}
                 key={ind} sx={{ flex: '1 1 300px', height: 'fit-content' }} onClick={
                    () => {
                      setSelectedIndex(ind)
                        setSelected(element)
                  }
                  }>
                    {console.log('element',element.platform)}
                    <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                      <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div className="d-flex gap-6">
                          <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                          {element.platform === 'insta'?'Instagram Hashtag':"Google Reviews"}
                          </Typography>
                          {<img src={element.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}
                        </div>
                        <Typography variant="h6" color="text.secondary" style={{fontSize: '14px', color: '#2E3039', fontWeight: 400, fontFamily: 'Inter'}} sx={{
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          textOverflow: 'ellipsis',
                        }}>
                          {console.log('element222',element)}
                          {element.platform === 'insta'?
                          "Earn "+element?.rewardValue?.offerPoints+" points for sharing hashtag #"+element?.rewardValue?.tagName+" on Instagram. Share & get rewarded!"
                          :"Earn bonus points for sharing on Google reviews. Share & get rewarded!"
                        }
                        </Typography>
                      </CardContent>
                      <CardMedia
                        component="img"
                        height="140"
                        style={{ padding: 12, width: '140px' }}
                        image={element.redeemingWayType === enums.RedeemingWays.PRODUCT
                          ? PngIcons.newRewardBlock
                          : element.redeemingWayType ===
                            enums.RedeemingWays.BIRTHDAY
                            ? PngIcons.newBD
                            : element.redeemingWayType === enums.RedeemingWays.REFERRAL
                            ? PngIcons.NewRBlock
                            : element.redeemingWayType ===
                            enums.RedeemingWays.PROMOTION
                            ? PngIcons.newPBlock
                            : element.redeemingWayType ===
                            enums.RedeemingWays.TAG
                            ? PngIcons.automationTags
                            : element.redeemingWayType ===
                            enums.RedeemingWays.INACTIVITY
                            ? PngIcons.NewIABlock:element.redeemingWayType === 'social' ?
                                            element.platform === 'insta'?'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/d720cafe-acd4-45f8-8e6f-b407b6f51d00/walletly':element.platform === 'google'?'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/a105d275-2a4f-4d61-0f17-29179feb3900/walletly':PngIcons.socialBanner
                            :''}
                        alt={element.earningName}
                      />
                    </CardActionArea>
                  </Card>
                ))
              }

            </div>
            <div className='bar mt_10 mb_20'></div>
            {selected?.platform==='insta'&&<div className='middle pt_28 mt_42' style={{background: '#F5F7FC'}}><img src={'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/2448033d-12fd-4f55-cfcc-120ca58cfe00/walletly'} /></div>}
            {selected?.platform==='google'&&<div className='middle pt_28 mt_42' style={{background: '#F5F7FC'}}><img src={'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/54587544-2a1f-48cf-8de6-804aad5a9800/walletly'} /></div>}
    
            <CustomsModal
        open={model}
        minWidth={"600px"}
        component={<>
          <div className="H22M">Confirm</div>
          <div className="B14R mt_10 mb_20">{'Are you sure you want to send bulk sms to '+ca?.length+' audience?'}</div>
          <div className="d-flex justify-content-end gap-10">
            <CustomButton
              varient="primary"
              btntext={"YES"}
              icon={deleteLoader &&
                <CircularProgress
                    size={20}
                    color={'inherit'} />}
              onClick={async() => {
                setDeleteLoader(true)
                // 
                
                  const response = await axios.post('https://api.walletly.ai/api/v4/pass/sendSocialPromo', {
                    brandId: brand._id,
                    id: selected?._id
                  });
              
                  // Handle the response
                  console.log('Response:', response.data);
                // 
              setDeleteLoader(false)
              setModel(false)
              }}
            />
            <CustomButton
              varient="secondary"
              btntext={"NO"}
              onClick={async() => {
                setModel(false)
              }}
            />
          </div>

        </>
        }
        // onClose={() => setrefmodel(false)}
      />
    
    </div>
  )
}

export default SocialCW