import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Modal from "react-modal";
import CustomsModal from "../../../components/CustomModal";
import CustomButton from "../../../components/CustomButton";
import { LayoutContext } from "../../../context/Layout.context";
import { utils } from "../../../utils";
import { BrandService } from "../../../services";
import { BrandContext } from "../../../context/Brand.context";
import { CircularProgress } from "@mui/material";

Modal.setAppElement("#root"); // Set the root element for accessibility

const initialItems = [
  { id: "1", name: "Asian white noodle with extra seafood", imageUrl: "url_to_image_1" },
  { id: "2", name: "Cho", imageUrl: "url_to_image_2" },
];

const New = () => {
    const brand = useContext(BrandContext);
    const [array1, setArray1] = useState([]); // First array
    const [array2, setArray2] = useState([]); // Second array
    const [array3, setArray3] = useState([]); // Second array
    const [array4, setArray4] = useState([]); // Second array
  const [items, setItems] = useState(initialItems);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [newItemUrl, setNewItemUrl] = useState("");
  const [newItemImage, setNewItemImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [selectedArray, setSelectedArray] = useState("array1"); // Dropdown state

  useEffect(() => {
    console.log('brand',brand);
    // setArray1([])
    // setArray2([])
    // setArray3([])
    // setArray4([])
    
    if(brand?.quarterly?.length>0){
        setArray1([])
        for (const element of brand.quarterly) {
            array1.push({
                id: element.id,
                name: element.name,
                imageUrl: element.image,
                url: element.url,
            })
        }
        setArray1([...array1])
    } 
    if(brand?.monthly?.length>0){
        setArray2([])
        for (const element of brand.monthly) {
            array2.push({
                id: element.id,
                name: element.name,
                imageUrl: element.image,
                url: element.url,
            })
        }
        setArray2([...array2])
    } 
    if(brand?.sponser?.length>0){
        setArray3([])
        for (const element of brand.sponser) {
            array3.push({
                id: element.id,
                name: element.name,
                imageUrl: element.image,
                url: element.url,
            })
        }
        setArray3([...array3])
    } 
    if(brand?.news?.length>0){
        setArray4([])
        for (const element of brand.news) {
            array4.push({
                id: element.id,
                name: element.name,
                imageUrl: element.image,
                url: element.url,
            })
        }
        setArray4([...array4])
    } 
  }, [])
  

  // Handle drag and drop
  const onDragEnd = (result, array, setArray) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(array);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    setArray(reorderedItems);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    console.log('file',file)
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setPreviewImage(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    //   setNewItemImage(file);
    // }
    setNewItemImage(file);
  };

  // Add item to the selected array
  const addItem = () => {
    if (!newItemUrl || !newItemName || !newItemImage) {
      alert("Please fill in both the name and upload an image.");
      return;
    }

    const newItem = {
      id: `${Date.now()}`, // Unique ID
      name: newItemName,
      url: newItemUrl,
      imageUrl: newItemImage,
    };

    if (selectedArray === "array1") {setArray1([...array1, newItem]);} 
    if (selectedArray === "array2") {setArray2([...array2, newItem]);} 
    if (selectedArray === "array3") {setArray3([...array3, newItem]);} 
    if (selectedArray === "array4") {setArray4([...array4, newItem]);}

    setModalIsOpen(false);
    setNewItemName("");
    setNewItemUrl("");
    setNewItemImage(null);
    setPreviewImage("");
  };

  // Delete item from an array
  const deleteItem = (name, array, setArray) => {
    setArray(array.filter((item) => item.name !== name));
  };

    const [state, setState] = useState({
        generalLoader: false,
        apiError: '',
        brandingLoader: false,
        termConditions :false
    })

  const layout = useContext(LayoutContext);
  
  useEffect(() => {
      layout.setLayout({
        title       : "Sponser",
        borderBottom: false,
        button      : <CustomButton btntext={"Save Changes"} icon={state.generalLoader && <CircularProgress color='inherit' size={14} />} onClick={async() => {
            setState({ generalLoader: true, apiError: '' })
            console.log(array1);
            console.log(array2);

            let payload = {
                _id: brand._id,
                quarterly: [],
                sponser: [],
                news: [],
                monthly: []
            }

            if(array1.length>0){
                for (const element of array1) {
                    if(typeof element.imageUrl === 'object' && element.imageUrl !== null ){
                        let abc = await utils.uploadImage({ file: element.imageUrl, desiredPath: element.id+'/'+element.imageUrl.size+'/'+utils.getRandomString(8) })
                        payload.quarterly.push({
                            id: element.id,
                            image: abc.response.data,
                            name: element.name,
                            url: element.url,
                        })
                    }
                    else if(typeof element.imageUrl === 'string' && element.imageUrl !== null ){
                        payload.quarterly.push({
                            id: element.id,
                            image: element.imageUrl,
                            name: element.name,
                            url: element.url,
                        })
                    }
                }
            }
            if(array2.length>0){
                for (const element of array2) {
                    if(typeof element.imageUrl === 'object' && element.imageUrl !== null ){
                        let abc = await utils.uploadImage({ file: element.imageUrl, desiredPath: element.id+'/'+element.imageUrl.size+'/'+utils.getRandomString(8) })
                        payload.monthly.push({
                            id: element.id,
                            image: abc.response.data,
                            name: element.name,
                            url: element.url,
                        })
                    }
                    else if(typeof element.imageUrl === 'string' && element.imageUrl !== null ){
                        payload.monthly.push({
                            id: element.id,
                            image: element.imageUrl,
                            name: element.name,
                            url: element.url,
                        })
                    }
                }
            }
            if(array3.length>0){
                for (const element of array3) {
                    if(typeof element.imageUrl === 'object' && element.imageUrl !== null ){
                        let abc = await utils.uploadImage({ file: element.imageUrl, desiredPath: element.id+'/'+element.imageUrl.size+'/'+utils.getRandomString(8) })
                        payload.sponser.push({
                            id: element.id,
                            image: abc.response.data,
                            name: element.name,
                            url: element.url,
                        })
                    }
                    else if(typeof element.imageUrl === 'string' && element.imageUrl !== null ){
                        payload.sponser.push({
                            id: element.id,
                            image: element.imageUrl,
                            name: element.name,
                            url: element.url,
                        })
                    }
                }
            }
            if(array4.length>0){
                for (const element of array4) {
                    if(typeof element.imageUrl === 'object' && element.imageUrl !== null ){
                        let abc = await utils.uploadImage({ file: element.imageUrl, desiredPath: element.id+'/'+element.imageUrl.size+'/'+utils.getRandomString(8) })
                        payload.news.push({
                            id: element.id,
                            image: abc.response.data,
                            name: element.name,
                            url: element.url,
                        })
                    }
                    else if(typeof element.imageUrl === 'string' && element.imageUrl !== null ){
                        payload.news.push({
                            id: element.id,
                            image: element.imageUrl,
                            name: element.name,
                            url: element.url,
                        })
                    }
                }
            }

            console.log('payload',payload);

            const { error, response } = await BrandService.Update({ toaster: true, payload })
            brand.updateBrand(response.data)
            setState({ generalLoader: false, apiError: '' })
        }} />
      });
    }, [modalIsOpen, array1, array2, selectedArray, state]);


  return (
    <div id="New" className="app">
      
      {/* Array 1 */}
      {<h2>Quarterly Giveaways</h2>}
      {/* <DragDropContext onDragEnd={(result) => onDragEnd(result, array1, setArray1)}>
        <Droppable droppableId="array1">
          {(provided) => (
            <ul className="item-list" {...provided.droppableProps} ref={provided.innerRef}>
              {array1.length>0 ? array1.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <li
                      className="item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <img src={item.imageUrl} alt={item.name} className="item-image" />
                      <span className="item-name">{item.name}</span>
                      <CustomButton varient="warningSecondary" onClick={() => deleteItem(item.id, array1, setArray1)} btntext={"Delete"} />
                    </li>
                  )}
                </Draggable>
              )):<div className="empty-state">
              <p onClick={() => setModalIsOpen(true)}>No sponsers available. Click here to add new sponsers!</p>
            </div>}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext> */}

<DragDropContext onDragEnd={(result) => onDragEnd(result, array1, setArray1)}>
  <Droppable droppableId="array2">
    {(provided) => (
      <ul className="item-list" {...provided.droppableProps} ref={provided.innerRef}>
        {/* Add New Sponser Box */}
        <li className="item add-new-box" onClick={() => setModalIsOpen(true)}>
          <span className="item-name" >Add New Quarterly</span>
        </li>

        {/* Draggable Items */}
        {array1.map((item, index) => (
          <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided) => (
              <li
                className="item"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <img src={(typeof item.imageUrl === 'string' && item.imageUrl !== null) ? item.imageUrl : (typeof item.imageUrl === 'object' && item.imageUrl !== null) ? URL.createObjectURL(item.imageUrl) : '' } alt={item.name} className="item-image" />
                <span className="item-name">{item.name}</span>
                <CustomButton
                  varient="warningSecondary1"
                  onClick={() => deleteItem(item.name, array1, setArray1)}
                  btntext={"Delete"}
                />
              </li>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </ul>
    )}
  </Droppable>
</DragDropContext>


      <h2 className="mt_30">Monthly Giveaways</h2>
<DragDropContext onDragEnd={(result) => onDragEnd(result, array2, setArray2)}>
  <Droppable droppableId="array2">
    {(provided) => (
      <ul className="item-list" {...provided.droppableProps} ref={provided.innerRef}>
        {/* Add New Sponser Box */}
        <li className="item add-new-box" onClick={() => setModalIsOpen(true)}>
          <span className="item-name" >Add New Monthly</span>
        </li>

        {/* Draggable Items */}
        {array2.map((item, index) => (
          <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided) => (
              <li
                className="item"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <img src={(typeof item.imageUrl === 'string' && item.imageUrl !== null) ? item.imageUrl : (typeof item.imageUrl === 'object' && item.imageUrl !== null) ? URL.createObjectURL(item.imageUrl) : '' } alt={item.name} className="item-image" />
                <span className="item-name">{item.name}</span>
                <CustomButton
                  varient="warningSecondary1"
                  onClick={() => deleteItem(item.name, array2, setArray2)}
                  btntext={"Delete"}
                />
              </li>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </ul>
    )}
  </Droppable>
</DragDropContext>
      <h2 className="mt_30">Our Sponsers</h2>
<DragDropContext onDragEnd={(result) => onDragEnd(result, array3, setArray3)}>
  <Droppable droppableId="array3">
    {(provided) => (
      <ul className="item-list" {...provided.droppableProps} ref={provided.innerRef}>
        {/* Add New Sponser Box */}
        <li className="item add-new-box" onClick={() => setModalIsOpen(true)}>
          <span className="item-name" >Add New Sponser</span>
        </li>

        {/* Draggable Items */}
        {array3.map((item, index) => (
          <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided) => (
              <li
                className="item"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <img src={(typeof item.imageUrl === 'string' && item.imageUrl !== null) ? item.imageUrl : (typeof item.imageUrl === 'object' && item.imageUrl !== null) ? URL.createObjectURL(item.imageUrl) : '' } alt={item.name} className="item-image" />
                <span className="item-name">{item.name}</span>
                <CustomButton
                  varient="warningSecondary1"
                  onClick={() => deleteItem(item.name, array3, setArray3)}
                  btntext={"Delete"}
                />
              </li>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </ul>
    )}
  </Droppable>
</DragDropContext>
      <h2 className="mt_30">News and Events</h2>
<DragDropContext onDragEnd={(result) => onDragEnd(result, array4, setArray4)}>
  <Droppable droppableId="array4">
    {(provided) => (
      <ul className="item-list" {...provided.droppableProps} ref={provided.innerRef}>
        {/* Add New Sponser Box */}
        <li className="item add-new-box" onClick={() => setModalIsOpen(true)}>
          <span className="item-name" >Add New News</span>
        </li>

        {/* Draggable Items */}
        {array4.map((item, index) => (
          <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided) => (
              <li
                className="item"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <img src={(typeof item.imageUrl === 'string' && item.imageUrl !== null) ? item.imageUrl : (typeof item.imageUrl === 'object' && item.imageUrl !== null) ? URL.createObjectURL(item.imageUrl) : '' } alt={item.name} className="item-image" />
                <span className="item-name">{item.name}</span>
                <CustomButton
                  varient="warningSecondary1"
                  onClick={() => deleteItem(item.name, array4, setArray4)}
                  btntext={"Delete"}
                />
              </li>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </ul>
    )}
  </Droppable>
</DragDropContext>


    

      <CustomsModal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        className="modal"
        overlayClassName="overlay"
        component={<div id="ModalNew">
        <h2>Add New Sponser</h2>

        <select
          value={selectedArray}
          onChange={(e) => setSelectedArray(e.target.value)}
          className="array-select"
          style={{marginTop: '12px', marginBottom: '25px'}}
        >
          <option value="array1">Quarterly</option>
          <option value="array2">Monthly</option>
          <option value="array3">Sponser</option>
          <option value="array4">News</option>
        </select>


        <input
          type="text"
          placeholder="Enter name"
          value={newItemName}
          onChange={(e) => setNewItemName(e.target.value)}
          className="modal-input"
        />
        <input
          type="text"
          placeholder="Enter Url"
          value={newItemUrl}
          onChange={(e) => setNewItemUrl(e.target.value)}
          className="modal-input"
        />
        <div className="image-upload-container">
          <label htmlFor="image-upload" className="image-upload-label">
            {console.log(typeof newItemImage)}
            {console.log(newItemImage)}
            {
            (typeof newItemImage === 'string' && newItemImage !== null) ? 
            <img src={newItemImage} alt="Preview" className="image-preview" />:
            (typeof newItemImage === 'object'&& newItemImage !== null) ? 
              <img src={URL.createObjectURL(newItemImage)} alt="Preview" className="image-preview" />:
            newItemImage === null ? 
            "Upload Image"
            //   <img src={URL.createObjectURL(newItemImage)} alt="Preview" className="image-preview" />
            // (
            // ) 
            : (
              "Upload Image"
            )
            }
          </label>
          <input
            id="image-upload"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="image-upload-input"
          />
        </div>
        <div className="modal-buttons">
          <button onClick={() => setModalIsOpen(false)} className="modal-button cancel-button">
            Cancel
          </button>
          <button onClick={addItem} className="modal-button save-button">
            Save
          </button>
        </div>
        </div>}
      />
    </div>
  );
};

export default New;