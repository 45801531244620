import axios from 'axios'
import { MainService } from '.'
import { Handlers, utils } from '../utils'
import env from '../config'
const service = '/v4/pass'

const Create = async ({payload, headers}) => await axios.post(env.API_URL+service, payload, {headers})

const sendBulkPromoInPerson = async ({payload, headers}) => await axios.post(env.API_URL+service+'/sendBulkPromoInPerson', payload)
const sendBulkSocialInPerson = async ({payload, headers}) => await axios.post(env.API_URL+service+'/sendBulkSocialInPerson', payload)
const sendBulkReferralInPerson = async ({payload, headers}) => await axios.post(env.API_URL+service+'/sendBulkReferralInPerson', payload)
const sendBulkCustomInPerson = async ({payload, headers}) => await axios.post(env.API_URL+service+'/sendBulkCustomInPerson', payload)
const sendBulkSMS = async ({payload, headers}) => await axios.post(env.API_URL+service+'/sendBulkSMS', payload)

const GetPassByCode = async ({payload, headers}) => await axios.post(env.API_URL+service + '/getpassbycode', payload)

const Update = async ({payload, headers}) => await axios.put(env.API_URL+service + '/update', payload, {headers})

// const UpdateTierPass = async ({payload, headers}) => await axios.put(env.API_URL+service + '/updateTierPass', payload, {headers})

const GetQueriedPass = async ({query}) => await axios.get(env.API_URL+service+'?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const GetPassesByBrandId = async ({query}) => await axios.get(env.API_URL+service+'/GetPassesByBrandId'+ '?'+utils.getQueryString(query), {headers: await MainService.getTokenHeader()})

const DeletePass = async ({payload}) => await axios.post(env.API_URL+service+'/delete',payload, {headers: await MainService.getTokenHeader()})

const GetBusinessCheckoutPass = async ({query, headers}) => await axios.get(env.API_URL+service+'/businessCheckout?'+utils.getQueryString(query), {headers})

const GetPublicPoints = async ({query}) => await axios.get(env.API_URL+service+'/publicpoints?'+utils.getQueryString(query))

const GetPublicPass = async ({query}) => await axios.get(env.API_URL+service+'/public?'+utils.getQueryString(query))

const GetPassJSON = async ({query}) => await axios.get(env.API_URL+service+'/json?'+utils.getQueryString(query))

const GetEntries = async ({query}) => await axios.get(env.API_URL+service+'/getEntries?'+utils.getQueryString(query))

const SendPassQrCode = async ({payload, headers}) => await axios.post(env.API_URL+service+'/sendpassqrcode', payload, {headers})

const Redirect = async ({payload}) => await axios.post(env.API_URL+service+'/lightspeedwebhook/redirect', payload)

const GetCompaines = async ({query}) => await axios.get(env.API_URL+service+'/lightspeedwebhook/companies?'+utils.getQueryString(query))

const PassService = {
    GetEntries              : Handlers.Services(GetEntries),
    Create                  : Handlers.Services(Create),
    sendBulkPromoInPerson                  : Handlers.Services(sendBulkPromoInPerson),
    sendBulkSocialInPerson                  : Handlers.Services(sendBulkSocialInPerson),
    sendBulkReferralInPerson                  : Handlers.Services(sendBulkReferralInPerson),
    sendBulkCustomInPerson                  : Handlers.Services(sendBulkCustomInPerson),
    sendBulkSMS                  : Handlers.Services(sendBulkSMS),
    Update                  : Handlers.Services(Update),
    GetQueriedPass          : Handlers.Services(GetQueriedPass),
    GetBusinessCheckoutPass : Handlers.Services(GetBusinessCheckoutPass),
    GetPassJSON             : Handlers.Services(GetPassJSON),
    GetPublicPoints         : Handlers.Services(GetPublicPoints),
    SendPassQrCode          : Handlers.Services(SendPassQrCode),
    DeletePass              : Handlers.Services(DeletePass),
    GetPublicPass           : Handlers.Services(GetPublicPass),
    Redirect                : Handlers.Services(Redirect),
    GetCompaines            : Handlers.Services(GetCompaines),
    GetPassByCode           : Handlers.Services(GetPassByCode),
    GetPassesByBrandId      : Handlers.Services(GetPassesByBrandId)
}

export default PassService
