// UnsubscribeButton.js
import axios from 'axios';
import React from 'react';
// import { useParams } from 'react-router-dom';
// import './UnsubscribeButton.css';
import { useLocation } from 'react-router-dom';

const DeletePass = () => {
    // const { email } = useParams();

    const query = new URLSearchParams(useLocation().search);
    const email = query.get('id');


    const handleUnsubscribe = async() => {
        console.log(email)
        try {
            // const email = "user@example.com"; // Make sure to get this from a secure source or input
            axios.post('https://api.walletly.ai/api/v4/pass/delete',{passId: email, delete: true})
            .then(response => {
                console.log('Data:', response);
                // alert(response.data); 
                alert("Pass Deleted!"); 
                setTimeout(() => {
                    window.close('','_parent','')
                }, 3000);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        } catch (error) {
            console.error("An error occurred:", error.response ? error.response.data.message : error.message);
            alert("Failed to unsubscribe. Please try again.");
        }
        
        // Place your API call here to handle the unsubscription.
    };

    

    return (
        <div className="unsubscribe-container mt_20">
            <h2>Delete Pass</h2>
            <p>If you no longer wish to receive loyalty rewards from us, click the button below.</p>
            <button className="unsubscribe-button" onClick={handleUnsubscribe}>
                Delete My Pass
            </button>
        </div>
    );
};

export default DeletePass;
