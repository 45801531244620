import React, { useContext, useEffect, useState } from 'react'
import { LayoutContext } from '../../../context/Layout.context';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LogoUploader from '../../../components/LogoUploader';
import { ImageModalV2 } from '../../../components/ImageModalV2/ImageModalV2';
import CustomButton from '../../../components/CustomButton';
import { BrandContext } from '../../../context/Brand.context';
import CustomTextField from '../../../components/CustomTextField';
import CustomTextArea from '../../../components/CustomTextArea';
import CustomModal from '../../../components/CustomModal';
import { CardActionArea, CircularProgress } from '@mui/material';
import { InventoryVld } from '../../../validation';
import localforage, * as localForage from "localforage";
import { useFormik } from 'formik';
import { DealService, InventoryService } from '../../../services';
import { utils } from '../../../utils';
import { AgencyContext } from '../../../context/Agency.context';
import { Brand } from '../../../enums';
import DeleteModal from '../../../components/DeleteModal';
import CustomsModal from '../../../components/CustomModal';

function AddDeal() {
    const history = useHistory();
    const brand = useContext(BrandContext);
    const layout = useContext(LayoutContext);
    const agency = useContext(AgencyContext);

    const [state, setstate] = useState({
        apiError: "",
        loader: false,
        deleteLoader: false,
        deleteModal: false,
        mainLoader: true,
        deleteItem: "",
        deleteIndex: "",
        result: [],
      });
      const [deleteLoader, setDeleteLoader] = useState(false);
      const [data, setData] = useState(state.result);
      const [modal1, setModal1] = useState(false);
      const [modal2, setModal2] = useState(false);
      const [imageModal, setImageModal] = useState({
        logo: { open: false, imageEvent: "", return: "" },
        cover: { open: false, imageEvent: "", return: "" },
      });
      const [deleteModal, setDeleteModal] = useState(false);
      
      useEffect(async () => {
        setstate({ ...state, mainLoader: true });
        let deal = await localForage.getItem("deal");
        // console.log('aaaa',deal);
        if (!deal) {
          const { response, error } = await DealService.Get({
            query: { brandId: brand._id, delete: false },
          });
          if (response) {
            localForage.setItem("deal", response.data);
            deal = response.data;
          }
        }
    
        setData(deal);
        setstate({ ...state, mainLoader: false, result: deal });
      }, [])


    const handleSubmit = async () => {
        setstate({ ...state, loader: true });
    
        if (formik.values.brandLogoFile == "" && formik.values.brandLogo == "") {
          utils.showToaster({
            position: "top-right",
            title: "Missing Reward Logo",
            message: "Reward Logo is required",
            severity: "error",
          });
        } else {
          const user = await localForage.getItem("user");
          const desiredPath = `${user._id}/${formik.values.productName}/${formik.values.brandLogoFile?.name}/${formik.values.brandLogoFile?.size}/${formik.values.brandLogoFile?.lastModified}`;
          console.log('formik.values.brandLogoFile',formik.values.brandLogoFile);
    
          console.log('desiredPath',desiredPath);
          
    
          let logo;
          if (formik.values.brandLogoFile && !formik.errors.brandLogoFile) {
            logo = await utils.uploadImage({
              file: formik.values.brandLogoFile,
              desiredPath,
            });
            if (logo.error)
              return setstate({
                ...state,
                loader: false,
                apiError: logo.error.message,
              });
          }
    
    
          console.log('logo',logo);
          
    
          let payload = {
            name: formik.values.name.toLowerCase(),
            description: formik.values.description,
            image: logo && logo.response.data,
            price: formik.values.price,
            agencyId: agency._id,
            brandId: brand._id,
          };
    
          let payload2 = {
            name: formik.values.name,
            description: formik.values.description,
            image: logo && logo.response.data,
            agencyId: agency._id,
            brandId: brand._id,
            discountType: Brand.DealDiscountType.AMOUNT_TOTAL_PRODUCT,
            discountValue: 0,
            discountedPrice: formik.values.price,
            totalPrice: formik.values.price,
          };
    
          // console.log('====================================');
          // console.log(payload);
          // console.log(payload2);
          // console.log('====================================');
    
          if (!agency._id) delete payload2.agencyId;
    
        //   if (!formik.values.edit) {
          if (history.location.state?._id==="") {
            console.log("new")
            payload.prefix = utils.getRandomString(3);
            const { response, error } = await InventoryService.Create({
              toaster: true,
              payload,
            });
            if (error) setstate({ ...state, loader: false, apiError: error.error });
            else {
              let inventory = await localforage.getItem("inventory");
              if (inventory == null) inventory = [];
              inventory.push(response.data);
              localforage.setItem("inventory", inventory);
    
              payload2.items = [response.data._id];
    
              const dealres = await DealService.Create({
                payload: { ...payload2 },
              });
              if (dealres.error)
                setstate({
                  ...state,
                  loader: false,
                  apiError: dealres.error.error,
                });
              else {
                let deal = await localForage.getItem("deal");
                if (deal === undefined || deal === null) deal = [];
                deal.push(dealres.response.data);
                localForage.setItem("deal", deal);
                setData(deal)
                state.result = deal;
                setstate({ ...state });
                setModal2(false);
                setModal1(false);
                setTimeout(() => {
                  formik.setValues({ ...initState });
                }, 2000);
              }
            }
          } else {
            console.log("old")
            if (formik.values?.name1 === formik.values.name) delete payload2.name;
            console.log('YesZoop');
            const { response, error } = await DealService.Update({
              toaster: true,
              payload: { ...payload2, _id: formik.values._id },
            });
            if (error) setstate({ ...state, loader: false, apiError: error.error });
            else {
              let deal = await localForage.getItem("deal");
              deal.map((element, idx) => {
                if (element._id === formik.values._id) deal[idx] = response.data;
              });
              setData(deal)
              localForage.setItem("deal", deal);
              state.result = deal;
              setstate({ ...state });
              setModal2(false);
              setModal1(false);
              setTimeout(() => {
                formik.setValues({ ...initState });
              }, 2000);
            }
          }
        }
        setstate({ ...state, loader: false });
        history.goBack()
      };

      const handleDelete = async (e) => {
        setDeleteLoader(true);
        const payload = {
          _id: formik.values._id,
          delete: true,
        };
    
        // console.log('payload', payload);
    
    
        const { error, response } = await DealService.Update({
          toaster: true,
          payload,
        });
    
        // console.log('response', response, error);
        if (error) setstate({ ...state, loader: false, apiError: error.error });
    
        let inventory = await localForage.getItem("deal");
        let temp = [];
        inventory.map((element, idx) => {
          if (element._id !== formik.values._id) temp.push(element);
        });
        localForage.setItem("deal", temp);
        setData(temp)
        state.result = temp;
        setstate({ ...state });
        formik.setValues({ ...initState });
        setDeleteLoader(false);
        setDeleteModal(false);
        setModal2(false);
        history.goBack()
      };
    
      const initState = {
        _id: history.location.state?._id||"",
        brandLogo: history.location.state?.brandLogo||"",
        brandLogoFile: history.location.state?.brandLogoFile||"",
        name: history.location.state?.name||"",
        name1: history.location.state?.name||"",
        price: history.location.state?.price||"0",
        description: history.location.state?.description||"",
        edit: false,
      };

      const formik = useFormik({
        initialValues: { ...initState },
        onSubmit: handleSubmit,
        validationSchema: InventoryVld.Create,
        // isInitialValid   : inventoryData && inventoryData.edit,
        isInitialValid: false,
        enableReinitialize: true,
      });

        useEffect(() => {
            layout.setLayout({
                borderBottom: true,
                edit        : false,
                title       : 'Reward',
                backTitles  : ['Reward Setup'],
                switch : '',
                button: <></>
            })
        }, [history.location.state])

  return (
    <div className='container'>
        <div>
      {/* {console.log('child 2',state.result.length)} */}
      <div id="AddInventory2" >
        <div className="modalComponent">
          <div className="mt_16">
            <CustomTextField
              label="Reward Name"
              className="w-50"
              name="name"
              placeholder="Enter name"
              value={formik.values.name}
              onChange={formik.handleChange}
              inputProps={{ onFocus: formik.handleBlur }}
              error={formik.touched.name && formik.errors.name}
              helperText={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : ""
              }
            />
          </div>

          <div
            className="col-12 pl_0 pr_0 z-index-20 "
            style={{ height: "165px" }}
          >
            <LogoUploader
              src={
                formik.values.brandLogoFile
                  ? URL.createObjectURL(formik.values.brandLogoFile)
                  : formik.values.brandLogo
              }
              label="Brand Cover"
              // size         = 's'
              displayLabel="Deal Image"
              hint={
                <div>
                  <div className="Link12R color-neutral60">
                    File requirements JPG, PNG, WebP upto 10 MB.ss
                  </div>
                  <div className="Link12R color-neutral60">
                    Minimum pixels required: 550 x 440.
                  </div>
                </div>
              }
              className="mt_24 mb_24 pl_0"
              error={formik.values.brandLogoFile && formik.errors.brandLogoFile}
              btntext="Upload Deal Image"
              btnName="Upload"
              htmlFor={"logoUpload"}
              onChange={(e) => {
                setImageModal({
                  ...imageModal,
                  logo: { ...imageModal.logo, open: true, imageEvent: e },
                });
              }}
            />
          </div>
          <div className="error">{formik.errors.brandLogoFile}</div>
          <div className="mt_16 mb_24">
            <CustomTextArea
              label="Description (Optional)"
              className="w-50"
              name="description"
              placeholder="Enter short description about the reward"
              value={formik.values.description}
              onChange={formik.handleChange}
              inputProps={{ onFocus: formik.handleBlur }}
              error={formik.touched.description && formik.errors.description}
              helperText={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : ""
              }
            />
          </div>
        </div>

        <CustomModal
          open={imageModal.logo.open}
          onClose={() =>
            setImageModal({
              ...imageModal,
              logo: { ...imageModal.logo, open: false },
            })
          }
          minWidth="470px"
          minHeight="470px"
          padding="0px"
          component={
            <ImageModalV2
              imageModal={imageModal}
              setImageModal={setImageModal}
              image={"logo"}
              type={"logo"}
              formik={formik}
            />
          }
        />
      </div>
      <div className="bar mb_20 mt_32"></div>
      <div className="w-100 d-flex justify-content-right">
        {
        data?.length>1&&
        history.location.state?._id!==""&&
        <CustomButton
          varient="warningSecondary1"
          btntext={"Delete"}
          onClick={() => {
            // formik.setValues({
            //   ...formik.values,
            //   _id: value._id,
            // });
            setDeleteModal(true);
          }}
        />}
        <CustomButton
          varient="secondary"
          btntext={"Cancel"}
          className={"ml_10"}
          onClick={() => {
            history.goBack()
            // setModal2(false);
            // setTimeout(() => {
            //   formik.setValues({ ...initState });
            // }, 2000);
          }}
        />
        <CustomButton
          disabled={state.loader || !formik.isValid}
          onClick={handleSubmit}
          icon={
            state.loader && <CircularProgress size={20} color={"inherit"} />
          }
          className={"ml_10"}
          btntext={history.location.state?._id !== "" ? "Update" : "Create"}
        />
      </div>
    </div>
    <CustomsModal
        open={deleteModal}
        minWidth={"600px"}
        component={
          <DeleteModal
            icon={deleteLoader}
            title="Reward"
            onCancel={() => setDeleteModal(false)}
            onDelete={handleDelete}
          />
        }
        onClose={() => setDeleteModal(false)}
      />
    </div>
  )
}

export default AddDeal